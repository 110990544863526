import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/messaging";
import "firebase/performance";
import "firebase/analytics";
import CircularProgress from "@material-ui/core/CircularProgress";
import Scroll from "react-scroll";
import ReactGA from "react-ga";
//import createBrowserHistory from "history/createBrowserHistory";
//const createBrowserHistory = require("history").createBrowserHistory;
import { FirebaseConfig } from "./firebase/config";
import moment from "moment";
import "moment/locale/ja";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

if (!firebase.apps.length) {
  firebase.initializeApp(FirebaseConfig);
}

// アナリティクス設定
const analytics = firebase.analytics();
const createBrowserHistory = require("history").createBrowserHistory;
const history = createBrowserHistory();
history.listen(({ pathname }) => {
  analytics.logEvent("page_view");
});
/*
ReactGA.initialize('UA-170004830-1');
const history = createBrowserHistory();
history.listen(({ pathname }) => {
  ReactGA.set({ page: pathname });
  ReactGA.pageview(pathname);
});
*/

const auth = firebase.auth();
const auth2 = firebase.auth;
const googleauth = new firebase.auth.GoogleAuthProvider();
const storage = firebase.storage();
const db = firebase.firestore();

// const message = firebase.messaging();
const functions = firebase.functions();
const perf = firebase.performance();

const SScroller = Scroll.scroller;
const AScroller = Scroll.animateScroll;

class RouterDiv extends Component {
  state = {
    user: null,
    auth: auth,
    auth2: auth2,
    localuser: null,
    googleauth: googleauth,
    accessToken: null,
    headerShow: false,
    messageShow: false,
    usercollection: null,
    coursecollection: null,
    userstorage: null,
    coursestorage: null,
    userscourse: null,
    qualitification: null,
    userQualification: null,
    //usersCourseData: [],
    //usersdiploma: null,
    openContent: false,
    isAutoLogin: false,
    prevpath: null,
    contentWindow: null,
    course: null,
    Header: null,
    Footer: null,
    Login: null,
    Detail: null,
    Questionnaire: null,
    Setting: null,
    StartPage: null,
    TopPage: null,
    NoMatch: null,
    Qualification: null,
    Content: null,
    MailAction: null,
    TermsPage: null,
    PayResultPage: null,
    AdminPage: null,
    DiplomaPage: null,
    HelpPage: null,
    BasicPage: null,
    userAnswerData: [],
    basicValue: null,
    basicInput: false,
  };

  userData = null;
  //usersCourseData = null;
  userQualification = null;
  //usersdiploma = null;
  Qualifications = null;
  userAnswerData = null;

  componentWillMount = async () => {
    const [
      Header,
      Footer,
      Login,
      User,
      Detail,
      Questionnaire,
      Setting,
      StartPage,
      TopPage,
      NoMatch,
      Qualification,
      Content,
      MailAction,
      TermsPage,
      PayResultPage,
      AdminPage,
      DiplomaPage,
      HelpPage,
    ] = await Promise.all([
      import("./component/Header"),
      import("./component/Footer"),
      import("./Pages/LoginAtom"),
      import("./Pages/User"),
      import("./Pages/CourseDetail"),
      import("./Pages/Questionnaire"),
      import("./Pages/UserSetting"),
      import("./Pages/StartPage"),
      import("./Pages/TopPage"),
      import("./Pages/NoMatch"),
      import("./Pages/Qualification"),
      import("./Pages/Content"),
      import("./Pages/MailAction"),
      import("./Pages/Tearms"),
      import("./Pages/PayResult"),
      import("./Pages/Admin"),
      import("./Pages/Diploma"),
      import("./Pages/Help"),
    ]);
    this.setState({
      Header: Header.default,
      Footer: Footer.default,
      Login: Login.default,
      User: User.default,
      Detail: Detail.default,
      Questionnaire: Questionnaire.default,
      Setting: Setting.default,
      StartPage: StartPage.default,
      TopPage: TopPage.default,
      NoMatch: NoMatch.default,
      Qualification: Qualification.default,
      Content: Content.default,
      MailAction: MailAction.default,
      TermsPage: TermsPage.default,
      PayResultPage: PayResultPage.default,
      AdminPage: AdminPage.default,
      DiplomaPage: DiplomaPage.default,
      HelpPage: HelpPage.default,
    });
  };
  componentDidMount() {
    const agent = window.navigator.userAgent.toLowerCase();
    const ie11 = agent.indexOf("trident/7") !== -1;

    // ログイン状態監視
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ isAutoLogin: true });
        const userRef = db.collection("users").doc(user.uid);
        const userStorageRef = storage.ref("/users").child(user.uid);
        try {
          const userdata = await userRef.get();
          if (!userdata.exists) {
            // 初回ログイン　かつ　ユーザ登録してから
            const groupsDefault = await db
              .collection("groups")
              .where("default", "==", true)
              .get();
            let groups = {};
            groupsDefault.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, " => ", doc.data());
              // nogroup 以外
              const data = doc.data();
              if (!data.nogroup) {
                groups[doc.id] = true;
              }
            });
            const defaultUserSettings = {
              area: "",
              birthday: null,
              email: user.email,
              gender: "",
              job: "",
              romaname: "",
              kananame: "",
              name: "",
              sports: "",
              groups,
              occupation: {},
              experience: {},
              position: {},
              industry: [],
              mainarea: {},
              lastlogin: moment(new Date()).unix(),
            };
            await userRef.set(defaultUserSettings, { merge: true });
            this.setState({
              usercollection: defaultUserSettings,
              userscourse: userRef,
              userstorage: userStorageRef,
            });
          } else {
            await userRef.set(
              { lastlogin: moment(new Date()).unix() },
              { merge: true }
            );
            this.setState({
              usercollection: { id: userdata.id, ...userdata.data() },
              userscourse: userRef,
              userstorage: userStorageRef,
            });
          }

          // setting の情報を取得
          const basicRef = db.collection("setting").doc("basic");
          const basicData = await basicRef.get();
          if (basicData.exists) {
            const basicValue = basicData.data().value;
            this.setState({
              basicValue,
            });
          }

          // ユーザデータ更新監視
          this.userData = userRef.onSnapshot((doc) => {
            // console.log(`changed !! ${change.doc.data()}`);
            this.setState({ usercollection: doc.data() });
          });

          // 資格データ更新監視
          this.Qualifications = db
            .collection("qualification")
            .orderBy("qid", "asc")
            .onSnapshot((qsnapshot) => {
              let qualitification = [];
              if (qsnapshot.size > 0) {
                qsnapshot.forEach((doc) => {
                  qualitification.push({ id: doc.id, ...doc.data() });
                });
              }
              this.setState({
                qualitification,
              });
            });

          // ユーザ資格データ更新監視
          this.userQualification = db
            .collection("usersqualification")
            .onSnapshot((uqsnapshot) => {
              let userQualification = [];
              if (uqsnapshot.size > 0) {
                uqsnapshot.forEach((doc) => {
                  userQualification.push({ id: doc.id, ...doc.data() });
                });
              }
              this.setState({
                userQualification,
              });
            });

          /*
          // ユーザコースデータ更新監視
          this.usersCourseData = db
            .collection("userscoursedata")
            .where("uid", "==", user.uid)
            .onSnapshot((udsnapshot) => {
              let usersCourseData = [];
              if (udsnapshot.size > 0) {
                udsnapshot.forEach((doc) => {
                  usersCourseData.push({ id: doc.id, ...doc.data() });
                });
              }
              this.setState({
                usersCourseData,
              });
            });
            */

          // ユーザアンサーデータ更新監視
          this.userAnswerData = db
            .collection("answer")
            .where("uid", "==", user.uid)
            .onSnapshot((udsnapshot) => {
              let userAnswerData = [];
              if (udsnapshot.size > 0) {
                udsnapshot.forEach((doc) => {
                  userAnswerData.push({
                    id: doc.id,
                    ...doc.data(),
                  });
                });
              }
              this.setState({
                userAnswerData,
              });
            });
          /*
          // ユーザ修了証・出席証更新監視
          this.usersdiploma = db
            .collection("usersdiploma")
            .where("uid", "==", user.uid)
            .onSnapshot((ulsnapshot) => {
              let usersdiploma = [];
              if (ulsnapshot.size > 0) {
                ulsnapshot.forEach((doc) => {
                  usersdiploma.push({ id: doc.id, ...doc.data() });
                });
              }
              this.setState({
                usersdiploma,
              });
            });
            */

          if (!user.emailVerified && !this.state.usercollection.admin) {
            this.setState({
              messageShow: true,
              isAutoLogin: false,
            });
            return;
          }
          this.setState({
            user: user,
            isAutoLogin: false,
          });
          /*
          await message.requestPermission();
          const usertoken = await message.getToken();
          await userRef.set({ token: usertoken}, { merge: true });
          message.onMessage((payload) => {
            console.log("Message received. ", payload);
          });
          */
          analytics.setUserId(user.uid);
        } catch (error) {
          /*
          if(error.code === "messaging/permission-blocked" || error.code === "messaging/token-subscribe-failed"){
            await userRef.set({ token: ''}, { merge: true });
          }*/
          console.log(error);
          this.setState({
            isAutoLogin: false,
          });
        }
      } else {
        if (this.userData) this.userData();
        if (this.Qualifications) this.Qualifications();
        if (this.userQualification) this.userQualification();
        //if (this.usersCourseData) this.usersCourseData();
        //if (this.usersdiploma) this.usersdiploma();
        if (this.userAnswerData) this.userAnswerData();
        this.setState({
          user: null,
          localuser: null,
          usercollection: null,
          coursecollection: null,
          userscourse: null,
          userstorage: null,
          userQualification: null,
          //usersCourseData: [],
          userAnswerData: [],
          //usersdiploma: null,
          isAutoLogin: false,
        });
      }
    });
  }
  componentWillUnmount() {
    this.setState({
      localuser: null,
      basicValue: null,
      basicInput: false,
    });
  }

  alertLogin = () => {
    if (!this.state.user) {
      return alert("ログインが必要です。");
    }
    if (
      this.state.usercollection.name === "" ||
      this.state.usercollection.kananame === "" ||
      this.state.usercollection.romaname === ""
    ) {
      return alert("ユーザ情報が未設定です。");
    }
  };

  openContentPage(course, history, location) {
    if (!this.state.user) {
      return alert("ログインが必要です。");
    }
    if (
      this.state.usercollection.name === "" ||
      this.state.usercollection.kananame === "" ||
      this.state.usercollection.romaname === ""
    ) {
      return alert("ユーザ情報が未設定です。");
    }
    if (this.state.contentWindow) {
      this.state.contentWindow.close();
    }
    console.log("router:openContentPage", course);
    const agent = window.navigator.userAgent.toLowerCase();
    //const ie11 = true; // ２画面での対応
    const ie11 = agent.indexOf("trident/7") !== -1;
    console.log("openContentPage:ie11", ie11);
    const nowContent = ie11
      ? null
      : window.open(
          course.contents,
          "_blank",
          "menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,width=1280,height=720"
        );
    //const nowContent = window.open(course.contents, '_blank'); // 2画面での対応
    this.setState({
      contentWindow: nowContent,
      openContent: true,
      course: course,
      prevpath: location,
    });
    history.push(`/member/user/contents/${course.id}`);
  }

  closeContentPage = (history) => (event) => {
    // console.log("Close")
    history.replace(this.state.prevpath);
    this.setState({
      openContent: false,
      course: null,
      prevpath: null,
    });
  };

  setUserConfig(config) {
    this.setState({
      localuser: config,
    });
  }

  headerSpy(bool) {
    this.setState({
      headerShow: bool,
    });
  }

  gotoUserPage = (history) => async (e) => {
    await history.push("/member/user");
    await window.location.reload();
  };

  messageHide = () => {
    this.setState({
      messageShow: false,
    });
  };

  scrollTo =
    (name = null) =>
    (event) => {
      if (name) {
        SScroller.scrollTo(name, {
          smooth: true,
          offset: -62,
          duration: 400,
        });
      } else {
        AScroller.scrollTo(0);
      }
    };

  basicInputExec = async (text) => {
    console.log(text);
    console.log("this.state.basicValue", this.state.basicValue);

    // setting の情報を取得
    if (!this.state.basicValue) {
      const basicRef = db.collection("setting").doc("basic");
      const basicData = await basicRef.get();
      if (basicData.exists) {
        const basicValue = basicData.data().value;
        if (text == basicValue) {
          this.setState({
            basicInput: true,
            basicValue,
          });
        } else {
          this.setState({
            basicValue,
          });
        }
      }
    } else {
      if (text == this.state.basicValue) {
        this.setState({
          basicInput: true,
        });
      }
    }
  };

  render() {
    const {
      Header,
      Footer,
      Login,
      User,
      Detail,
      Setting,
      StartPage,
      TopPage,
      NoMatch,
      Qualification,
      Content,
      MailAction,
      TermsPage,
      PayResultPage,
      AdminPage,
      DiplomaPage,
      HelpPage,
      BasicPage,
      openContent,
      qualitification,
      //usersCourseData,
      userQualification,
      //usersdiploma,
      Questionnaire,
      userAnswerData,
    } = this.state;
    if (Header) {
      return (
        <Router>
          <div style={{ height: "100%", position: "relative" }}>
            <Header
              user={this.state.user}
              auth={this.state.auth}
              headershow={this.state.headerShow}
              localuser={this.state.usercollection}
              isAutoLogin={this.state.isAutoLogin}
              scrollTo={this.scrollTo}
            />
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => {
                  return this.state.user ? (
                    <Redirect
                      to={{
                        pathname: "/member/user",
                      }}
                    />
                  ) : (
                    <Login
                      auth={this.state.auth}
                      messageShow={this.state.messageShow}
                      messageHide={this.messageHide}
                      googleauth={this.state.googleauth}
                      isAutoLogin={this.state.isAutoLogin}
                      {...props}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/help"
                render={(props) => {
                  return <HelpPage {...props} />;
                }}
              />

              <Route
                exact
                path="/top/:toppage"
                render={(props) => {
                  return (
                    <StartPage
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      userdata={this.state.userscourse}
                      //usersCourseData={usersCourseData}
                      qualitification={qualitification}
                      userQualification={userQualification}
                      localuser={this.state.usercollection}
                      headerspy={(bool) => {
                        this.headerSpy(bool);
                      }}
                      openContentPage={(id) => {
                        this.openContentPage(id, props.history, props.location);
                      }}
                      alertLogin={this.alertLogin}
                      //diploma={usersdiploma}
                      functions={functions}
                      {...props}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/member/login"
                render={(props) => {
                  return this.state.user ? (
                    <Redirect
                      to={{
                        pathname: "/member/user",
                      }}
                    />
                  ) : (
                    <Login
                      auth={this.state.auth}
                      messageShow={this.state.messageShow}
                      messageHide={this.messageHide}
                      googleauth={this.state.googleauth}
                      isAutoLogin={this.state.isAutoLogin}
                      {...props}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/member/user"
                render={(props) => {
                  return this.state.user ? (
                    <User
                      auth={this.state.auth}
                      db={db}
                      userdata={this.state.userscourse}
                      user={this.state.user}
                      //
                      //usersCourseData={usersCourseData}
                      qualitification={qualitification}
                      userQualification={userQualification}
                      localuser={this.state.usercollection}
                      openContentPage={(id) => {
                        this.openContentPage(id, props.history, props.location);
                      }}
                      alertLogin={this.alertLogin}
                      //diploma={usersdiploma}
                      functions={functions}
                      {...props}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login",
                      }}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/member/user/settings/:firstflg"
                render={(props) => {
                  const { classes, ...other } = props;
                  return this.state.user ? (
                    <Setting
                      auth={this.state.auth}
                      auth2={auth2}
                      db={db}
                      localuser={this.state.usercollection}
                      user={this.state.user}
                      storage={storage}
                      firebase={firebase}
                      //usersCourseData={usersCourseData}
                      qualitification={qualitification}
                      userQualification={userQualification}
                      functions={functions}
                      {...props}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login",
                      }}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/member/user/qualification/:qualificationId"
                render={(props) => {
                  return this.state.user ? (
                    <Qualification
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      localuser={this.state.usercollection}
                      qualitification={qualitification}
                      userQualification={userQualification}
                      {...props}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login",
                      }}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/member/user/diploma/:diplomaId"
                render={(props) => {
                  return this.state.user ? (
                    <DiplomaPage
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      localuser={this.state.usercollection}
                      qualitification={qualitification}
                      userQualification={userQualification}
                      //diploma={usersdiploma}
                      {...props}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login",
                      }}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/member/user/contents/:contentsId"
                render={(props) => {
                  return this.state.user && this.state.course ? (
                    <Content
                      open={openContent}
                      user={this.state.user}
                      db={db}
                      course={this.state.course}
                      contentWindow={this.state.contentWindow}
                      userAnswerData={userAnswerData}
                      closeContentPage={this.closeContentPage(props.history)}
                      functions={functions}
                      {...props}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login",
                      }}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/member/courses/:id"
                render={(props) => {
                  const { classes, ...other } = props;
                  return (
                    <Detail
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      localuser={this.state.usercollection}
                      openContentPage={(id) => {
                        this.openContentPage(id, props.history, props.location);
                      }}
                      alertLogin={this.alertLogin}
                      //usersCourseData={usersCourseData}
                      qualitification={qualitification}
                      userQualification={userQualification}
                      //diploma={usersdiploma}
                      functions={functions}
                      {...other}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/member/questionnaire/:courseId/:questionnaireId"
                render={(props) => {
                  const { classes, ...other } = props;
                  return this.state.auth ? (
                    <Questionnaire
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      openContentPage={(id) => {
                        this.openContentPage(id, props.history, props.location);
                      }}
                      alertLogin={this.alertLogin}
                      userQualification={userQualification}
                      localuser={this.state.usercollection}
                      functions={functions}
                      {...other}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login",
                      }}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/member/termsofservice"
                render={(props) => {
                  const { classes, ...other } = props;
                  return <TermsPage {...other} />;
                }}
              />

              <Route
                exact
                path="/member/auth/action"
                render={(props) => {
                  const { classes, ...other } = props;
                  return (
                    <MailAction
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      gotoUserPage={this.gotoUserPage}
                      {...other}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/member/admin"
                render={(props) => {
                  const { classes, ...other } = props;
                  return this.state.user &&
                    this.state.usercollection &&
                    this.state.usercollection.admin ? (
                    <AdminPage
                      auth={this.state.auth}
                      localuser={this.state.usercollection}
                      firebase={firebase}
                      storage={storage}
                      db={db}
                      user={this.state.user}
                      functions={functions}
                      {...other}
                    />
                  ) : (
                    <Redirect to={{ pathname: "/member/login" }} />
                  );
                }}
              />

              <Route
                exact
                path="/member/paysuccess"
                render={(props) => {
                  return <PayResultPage {...props} status="success" />;
                }}
              />

              <Route
                exact
                path="/member/payerror"
                render={(props) => {
                  return <PayResultPage {...props} status="error" />;
                }}
              />

              <Route
                exact
                path="/member/paycancel"
                render={(props) => {
                  return <PayResultPage {...props} status="cancel" />;
                }}
              />

              <Route
                exact
                path="/member/help"
                render={(props) => {
                  return <HelpPage {...props} />;
                }}
              />

              <Route
                render={(props) => {
                  return <NoMatch {...props} />;
                }}
              />
            </Switch>
            <Footer
              user={this.state.user}
              auth={this.state.auth}
              headershow={this.state.headerShow}
              localuser={this.state.usercollection}
              isAutoLogin={this.state.isAutoLogin}
              scrollTo={this.scrollTo}
            />
          </div>
        </Router>
      );
    } else {
      return <CircularProgress size={56} className="buttonProgress" />;
    }
  }
}

export default RouterDiv;
